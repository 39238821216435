<template>
	<div class="mdc-select mdc-select--filled">
		<input type="hidden" :name="name" :id="id" v-bind="attrs" v-on="listeners" />
		<div class="mdc-select__anchor"
			role="button"
			aria-haspopup="listbox"
			aria-expanded="false"
			aria-labelledby="select-label selected-text">
			<span class="mdc-select__ripple"></span>
			<span id="select-label" class="mdc-floating-label" v-html="label"></span>
			<span class="mdc-select__selected-text-container">
			<span id="selected-text" class="mdc-select__selected-text"></span>
			</span>
			<span class="mdc-select__dropdown-icon">
			<svg
				class="mdc-select__dropdown-icon-graphic"
				viewBox="7 10 10 5" focusable="false">
				<polygon
					class="mdc-select__dropdown-icon-inactive"
					stroke="none"
					fill-rule="evenodd"
					points="7 10 12 15 17 10">
				</polygon>
				<polygon
					class="mdc-select__dropdown-icon-active"
					stroke="none"
					fill-rule="evenodd"
					points="7 15 12 10 17 15">
				</polygon>
			</svg>
			</span>
			<span class="mdc-line-ripple"></span>
		</div>


		<div class="mdc-select__menu mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth">
			<ul class="mdc-deprecated-list" aria-label="Listbox" role="listbox" :class="{'mdc-deprecated-list--two-line':twoLine}">
				<li class="mdc-deprecated-list-item"
					role="option"
					:aria-selected="option.value === attrs.value"
					:class="{'mdc-list-item--selected': option.value === attrs.value}"
					:data-value="option.value"
					:key="`${id}_${key}`"
					v-for="(option, key) in options"
					>
					<span class="mdc-deprecated-list-item__text">
						<template v-if="twoLine">
							<span class="mdc-deprecated-list-item__primary-text" v-html="option.label"></span>
							<span class="mdc-deprecated-list-item__secondary-text" v-if="option.hint" v-html="option.hint"></span>
						</template>
						<template v-if="!twoLine">{{ option.label }}</template>
					</span>
				</li>
			</ul>
		</div>
	</div>

</template>

<script>
import { MDCSelect } from '@material/select'
import { v4 } from 'uuid'

export default {
	name: 'selectField',
	data: () => ({
		select: null,
	}),
	props: {
		options: {
			type: [Array],
			default: () => ([]),
		},
		id: {
			type: String,
			default: v4(),
		},
		name: String,
		label: String,
		twoLine: Boolean,
	},
	computed: {
		attrs() {
			return this.$attrs
		},
		listeners() {
			return {
				...this.$listeners,
				input: event => this.input(event),
				change: event => this.input(event),
			}
		},
		selected() {
			const found = this.options.find((option) => option?.value == this.attrs.value)
			if (found) {
				return found.label
			}
			return ''
		},
	},
	mounted() {
		this.select = new MDCSelect(this.$el)
		this.select.listen('MDCSelect:change', () => {
			if (!this.options) return
			let _selected = this.options[this.select.selectedIndex]
			if (_selected != undefined && _selected != null) {
				this.$emit('input', _selected.value)
			}
		})
	},
	methods: {
		input(event) {
			if (event && event.target != undefined && event.target != null) {
				this.$emit('input', event.target.value)
			}
		},
	},
	watch: {
		attrs(val) {
			if (val && (val.value == undefined || val.value == null) && this.select) {
				this.select.selectedIndex = -1
			}

			if (this.select && (val && val.value != undefined && val.value != null) && (this.options && (this.options || []).length) && (this.select.menuItemValues && (this.select.menuItemValues || []).length)) {
				const idx = this.options.findIndex(o => o.value == val.value)
				const option = this.options.find(o => o.value == val.value)
				if (idx > -1)
					this.select.foundation.adapter.setSelectedIndex(idx)
				if (option)
					this.select.foundation.adapter.setSelectedText(option.label)
			}
		},
		selected: {
			immediate: true,
			handler (val) {
				if (val && this.$el) {
					this.$el.querySelector('#selected-text').innerHTML = val
					this.$el.querySelector('#select-label').classList.add('mdc-floating-label--float-above')
				}
			}
		},
		options: {
			immediate: true,
			handler (val, oldVal) {
				if (this.select) {
					setTimeout(() => {
						this.select.layoutOptions()
					}, 0)
				}
			},
		},
	},
}
</script>

<style lang="scss" scoped>
@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
@use "@material/select/mdc-select";

.mdc-select {
	$select: &;

	&__menu {
		width: 100%;
	}

	&:not(#{$select}--disabled) {

		&#{$select}--focused {
			.mdc-floating-label {
				color: modules.color_('primary');
			}
		}
	}

	&--activated {
		#{$select}__anchor {
			border-color: modules.color_('primary');
		}
	}

	&__anchor {
		#{$select}__ripple {
			&:before {
				background-color: transparent;
			}
		}
	}

	.mdc-line-ripple {
		display: none;
	}

	.mdc-deprecated-list-item {
		&:hover,
		&:focus {
			background-color: rgba(220,220,220,0.5);
		}

		&.mdc-list-item--selected {
			background-color: rgba(modules.color_('primary'), 1);
			color: modules.color_('primary', 'text');
		}
	}
}
</style>
